import React from "react"

import { GetCourtType } from "api/bookingDashboard/getCourtDetailsApi"

export const CURRENCY_LIST = [
  { name: "USD", id: "USD" },
  // { name: "CAD", id: "CAD" },
  // { name: "GBP", id: "GBP" },
  // { name: "EUR", id: "EUR" },
]

export const PROMO_CODE_TYPE = [
  { name: "PR", id: "PR" },
  { name: "Promotion", id: "Promotion" },
  { name: "Credit", id: "Credit" },
  { name: "Coupon", id: "Coupon" },
  { name: "Crowd Funding", id: "Crowd Funding" },
  { name: "Internal Travel", id: "Internal Travel" },
  { name: "Admin", id: "Admin" },
]

export type CurrencyListType = "USD" | "CAD" | "GBP" | "EUR"

export const ADMIN_ROLE_LIST = [
  { name: "Root Admin", id: "ROOTADMIN" },
  { name: "Admin", id: "ADMIN" },
  { name: "TA Admin", id: "TAADMIN" },
  { name: "AM Admin", id: "AMADMIN" },
  { name: "TA User Admin", id: "TAUSERADMIN" },
  { name: "AM User Admin", id: "AMUSERADMIN" },
  { name: "Developer", id: "DEVELOPER" },
  { name: "Marketing Admin", id: "MARKETINGADMIN" },
]

export const BOOKING_STATUS = [
  { title: "ALL", value: "ALL" },
  { title: "Pending ID", value: "pendingIdVerification" },
  { title: "Success", value: "success" },
  { title: "Canceled", value: "cancel" },
  { title: "Failed", value: "fail" },
  { title: "Pending Capture", value: "uncapturedPayment" },
]

export const isConfirmed = "confirmed"
export const isDone = "done"
export const isPercentage = "PERCENT"
export const applied = "applied"

// enum for booking state
export const STATE_UPCOMING = "upcoming"
export const STATE_CHECKED_IN = "checked_in"
export const STATE_CHECKED_OUT = "checked_out"
export const STATE_DASHED_CHECKED_OUT = "checked-out"
export const STATE_PENDING = "pending"

// enum for booking status
export const STATUS_SUCCESS = "success"
export const STATUS_CANCEL = "cancel"
export const STATUS_FAIL = "fail"
export const STATUS_PENDING = "pending"

// enum for booking payment status
export const CHARGE_SUCCESS = "charge_success"
export const AUTH_FAILED = "auth_failed"
export const AUTH_SUCCESS = "auth_success"
export const AUTH_CANCELLED = "auth_cancelled"
export const CHARGE_FAILED = "charge_failed"

// Define the enums for refund and reverse options
export const FULL_REFUND = "Full Refund"
export const FULL_REVERSE = "Full Reverse"
export const REFUND_WITHOUT_SERVICE_FEE = "Refund without Service Fee"
export const REFUND_INSURANCE = "Refund Insurance"
export const PARTIAL_REFUND = "Partial Refund"
export const PARTIAL_REVERSE = "Partial Reverse"
export const REFUND_WITHOUT_INSURANCE = "Refund without Insurance"
export const REFUND_WITHOUT_SERVICE_FEE_AND_INSURANCE =
  "Refund without Service Fee and Insurance"

// review status
export const PENDING_REVIEW = "pending_review"
export const DISPUTED = "disputed"

export const BCOM_BOOKING_STATUS = [
  { title: "ALL", value: "ALL" },
  // Removed from the filtered list after client request
  // { title: "Confirmed", value: "confirmed" },
  // { title: "First Email", value: "firstEmail" },
  { title: "PENDING ACTION", value: "takeAction" },
  { title: "DONE", value: "done" },
]

export enum BcomBookingStatus {
  ALL = "ALL",
  confirmed = "Confirmed",
  firstEmail = "First Email",
  takeAction = "Send Email",
  done = "Done",
}

export const INVOICE_STATUS = [
  { title: "ALL", value: "ALL" },
  { title: "Open", value: "OPEN" },
  { title: "Draft", value: "DRAFT" },
  { title: "Paid", value: "PAID" },
  { title: "Overdue", value: "OVERDUE" },
]

export const INVOICE_TYPE = [
  { name: "Damages", id: "Damages" },
  { name: "Custom", id: "Custom" },
  { name: "Extended Stay", id: "Extended Stay" },
  { name: "Property Relocation", id: "Property Relocation" },
  { name: "Additional Fees", id: "Additional Fees" },
]

export const BOOKING_STATE = [
  { title: "ALL", value: "ALL" },
  { title: "Checked-In", value: "Checked-In" },
  { title: "Checked-Out", value: "Checked-Out" },
  { title: "Upcoming", value: "Upcoming" },
]

export const COMPANY_TYPE = [
  { title: "ALL", value: "ALL" },
  { title: "LIVE", value: "LIVE" },
  { title: "ARCHIVED", value: "ARCHIVED" },
  { title: "TEST", value: "TEST" },
  { title: "IN-PROCESS", value: "IN-PROCESS" },
  { title: "ON-HOLD", value: "ON-HOLD" },
]

export const HOST_PAYOUT_STATUS = [
  { title: "Upcoming", value: "Upcoming" },
  { title: "Transferred", value: "Transferred" },
  { title: "Reversed", value: "Reversed" },
  { title: "Canceled", value: "Canceled" },
]

export const USER_DASHBOARD_STATUS = [
  { title: "Active", value: "Active" },
  { title: "InActive", value: "InActive" },
  { title: "Deleted", value: "Deleted" },
  { title: "All", value: "All" },
]

export const MANAGE_PROMOCODE_TYPE = [
  { title: "Open", value: "OPEN" },
  { title: "Voided", value: "VOIDED" },
  { title: "Closed", value: "CLOSED" },
  { title: "Future", value: "FUTURE" },
]

export const REDEEMED_PROMOCODE_TYPE = [
  {
    title: "Used",
    value: "true",
  },
  {
    title: "Unused",
    value: "false",
  },
]

export const HOST_FEES_LIST = [
  { title: "Select fee", value: "Select fee" },
  { title: "Additional Guest fee", value: "Additional Guest fee" },
  { title: "Pet fee", value: "Pet fee" },
  { title: "Custom", value: "Custom" },
]

export const REFUND_LIST = [
  { title: "Refund without Service Fee", value: "Refund without Service Fee" },
  { title: "Full Refund", value: "Full Refund" },
  { title: "Refund Insurance", value: "Refund Insurance" },
  { title: "Partial Refund", value: "Partial Refund" },
]

export const REVERSE_LIST = [
  { title: "Full Reverse", value: "Full Reverse" },
  { title: "Partial Reverse", value: "Partial Reverse" },
]

export const ADD_MORE_LIST = [
  { title: "Select fee", value: "Select fee" },
  { title: "Damages", value: "Damages" },
  { title: "Custom", value: "Custom" },
]

export const TRAVELER_ADD_MORE_LIST = [
  { title: "Select fee", value: "Select fee" },
  { title: "Damages", value: "Damages" },
  { title: "Full Refund", value: "Full Refund" },
  { title: "Partial Refund", value: "Partial Refund" },
  { title: "Custom", value: "Custom" },
]

export const FAQ_TYPE = [
  { title: "Host", value: "1" },
  { title: "Traveller", value: "2" },
  { title: "Both", value: "0" },
]

export const FAQ_LANGUAGE_TYPE = [
  { title: "English", value: "en" },
  { title: "French", value: "fr" },
]

export const FAQ_CATEGORY_TYPE = [
  { title: "YOUR_ACCOUNT", value: "YOUR_ACCOUNT" },
  {
    title: "PAYMENT_AND_PRICING",
    value: "PAYMENT_AND_PRICING",
  },
  { title: "SEARCH_YOUR_DAY", value: "SEARCH_YOUR_DAY" },
  {
    title: "BOOKING_AND_RESERVATION",
    value: "BOOKING_AND_RESERVATION",
  },
  { title: "PROMO_CODE", value: "PROMO_CODE" },
  { title: "REVIEW", value: "REVIEW" },
]

export const HISTORY_DATE_LIST = [
  { title: "Current", value: "current" },
  { title: "06-31-2023", value: "06-31-2023" },
  { title: "10-22-2023", value: "10-22-2023" },
  { title: "08-10-2023", value: "08-10-2023" },
  { title: "02-08-2023", value: "02-08-2023" },
  { title: "05-07-2023", value: "05-07-2023" },
  { title: "09-16-2023", value: "09-16-2023" },
]

export const EMAIL_TYPE_LIST = [
  {
    name: "HOST",
    id: "HOST",
  },
  {
    name: "TRAVELER",
    id: "TRAVELER",
  },
  {
    name: "RECEIPT",
    id: "RECEIPT",
  },
  {
    name: "ALL",
    id: "ALL",
  },
]
export const PMS_FEES_SYSTEM_LIST = [
  {
    title: "STREAMLINE",
    value: "STREAMLINE",
  },

  {
    title: "TRACK",
    value: "TRACK",
  },
]
export const PMS_SYSTEM_LIST = [
  {
    name: "STREAMLINE",
    id: "STREAMLINE",
  },
  {
    name: "HOSTAWAY",
    id: "HOSTAWAY",
  },
  {
    name: "TRACK",
    id: "TRACK",
  },
  {
    name: "HOSTIFY",
    id: "HOSTIFY",
  },
  {
    name: "INNTOPIA",
    id: "INNTOPIA",
  },
  {
    name: "BAREFOOT",
    id: "BAREFOOT",
  },
  {
    name: "GUESTY",
    id: "GUESTY",
  },
  {
    name: "SWEETHOMEVACATION",
    id: "SWEETHOMEVACATION",
  },
  {
    name: "RENTALS_UNITED",
    id: "RENTALS_UNITED",
  },
  {
    name: "BRIGHTSIDE",
    id: "BRIGHTSIDE",
  },
  {
    name: "LODGIFY",
    id: "LODGIFY",
  },
]

export const FEES_APPLIED = [
  { name: "PERCENT", id: "PERCENT" },
  { name: "FIXED_FEE", id: "FIXED_FEE" },
]

export const BOOKING_TYPE = [
  { name: "BOOKING", id: "BOOKING" },
  { name: "DAILY", id: "DAILY" },
]

export const TAX_TYPE = [
  { name: "inclusive", id: "inclusive" },
  { name: "exclusive", id: "exclusive" },
  { name: "cumulative", id: "cumulative" },
]

export const REVIEW_TYPE = [
  { title: "ALL", value: "ALL" },
  { title: "Pending Review", value: "pending_review" },
  { title: "Approved", value: "approved" },
  { title: "Rejected", value: "rejected" },
  { title: "Disputed", value: "disputed" },
  { title: "Dispute Approved", value: "dispute_approved" },
  { title: "Dispute Rejected", value: "dispute_rejected" },
]

export const FEEDBACK_REVIEW_TYPE = [
  { title: "Approved", value: "approved" },
  { title: "Rejected", value: "rejected" },
  { title: "Dispute Rejected", value: "dispute_rejected" },
  { title: "Dispute Approved", value: "dispute_approved" },
]

export const customStyles = {
  rows: {
    highlightOnHoverStyle: {
      outlineColor: "#eee",
    },
  },
}

export type SortDirectionType = "ASC" | "DESC"

export interface Pageable {
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: {
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    unpaged: boolean
  }
  size: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  totalElements: number
  totalPages: number
}

export enum ApiStatus {
  ACCEPTED = "ACCEPTED",
  ERROR = "ERROR",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
}
export interface ResponseData {
  apiStatus: ApiStatus
  errorCode?: number
  errorMessage?: string
  message?: string
  status?: number
  time: string
}

export const countryCodes = [
  { name: "(+1)", id: "+1" },
  { name: "(+91)", id: "+91" },
]

export const allCountryCodes = [
  ["United States", "us", "1"],
  ["India ", "in", "91"],
  ["Afghanistan (‫افغانستان‬‎)", "af", "93"],
  ["Åland Islands", "ax", "358"],
  ["Albania (Shqipëri)", "al", "355"],
  ["Algeria (‫الجزائر‬‎)", "dz", "213"],
  ["American Samoa", "as", "1684"],
  ["Andorra", "ad", "376"],
  ["Angola", "ao", "244"],
  ["Anguilla", "ai", "1264"],
  ["Antarctica", "aq", "672"],
  ["Antigua and Barbuda", "ag", "1268"],
  ["Argentina", "ar", "54"],
  ["Armenia (Հայաստան)", "am", "374"],
  ["Aruba", "aw", "297"],
  ["Australia", "au", "61"],
  ["Austria (Österreich)", "at", "43"],
  ["Azerbaijan (Azərbaycan)", "az", "994"],
  ["Bahamas", "bs", "1242"],
  ["Bahrain (‫البحرين‬‎)", "bh", "973"],
  ["Bangladesh (বাংলাদেশ)", "bd", "880"],
  ["Barbados", "bb", "1246"],
  ["Belarus (Беларусь)", "by", "375"],
  ["Belgium (België)", "be", "32"],
  ["Belize", "bz", "501"],
  ["Benin (Bénin)", "bj", "229"],
  ["Bermuda", "bm", "1441"],
  ["Bhutan (འབྲུག)", "bt", "975"],
  ["Bolivia", "bo", "591"],
  ["Bosnia and Herzegovina (Босна и Херцеговина)", "ba", "387"],
  ["Botswana", "bw", "267"],
  ["Bouvet Island", "bv", "47"],
  ["Brazil (Brasil)", "br", "55"],
  ["British Indian Ocean Territory", "io", "246"],
  ["British Virgin Islands", "vg", "1284"],
  ["Brunei", "bn", "673"],
  ["Bulgaria (България)", "bg", "359"],
  ["Burkina Faso", "bf", "226"],
  ["Burundi (Uburundi)", "bi", "257"],
  ["Cambodia (កម្ពុជា)", "kh", "855"],
  ["Cameroon (Cameroun)", "cm", "237"],
  ["Cape Verde (Kabu Verdi)", "cv", "238"],
  ["Caribbean Netherlands", "bq", "599"],
  ["Cayman Islands", "ky", "1345"],
  ["Central African Republic (République centrafricaine)", "cf", "236"],
  ["Chad (Tchad)", "td", "235"],
  ["Chile", "cl", "56"],
  ["China (中国)", "cn", "86"],
  ["Christmas Island", "cx", "61"],
  ["Cocos (Keeling) Islands", "cc", "61"],
  ["Colombia", "co", "57"],
  ["Comoros (‫جزر القمر‬‎)", "km", "269"],
  ["Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)", "cd", "243"],
  ["Congo (Republic) (Congo-Brazzaville)", "cg", "242"],
  ["Cook Islands", "ck", "682"],
  ["Costa Rica", "cr", "506"],
  ["Côte d’Ivoire", "ci", "225"],
  ["Croatia (Hrvatska)", "hr", "385"],
  ["Cuba", "cu", "53", "+..-.-...-...."],
  ["Curaçao", "cw", "599"],
  ["Cyprus (Κύπρος)", "cy", "357"],
  ["Czech Republic (Česká republika)", "cz", "420"],
  ["Denmark (Danmark)", "dk", "45"],
  ["Djibouti", "dj", "253"],
  ["Dominica", "dm", "1767"],
  ["Ecuador", "ec", "593"],
  ["Egypt (‫مصر‬‎)", "eg", "20"],
  ["El Salvador", "sv", "503"],
  ["Equatorial Guinea (Guinea Ecuatorial)", "gq", "240"],
  ["Eritrea", "er", "291"],
  ["Estonia (Eesti)", "ee", "372"],
  ["Ethiopia", "et", "251"],
  ["Falkland Islands (Islas Malvinas)", "fk", "500"],
  ["Faroe Islands (Føroyar)", "fo", "298"],
  ["Fiji", "fj", "679"],
  ["Finland (Suomi)", "fi", "358"],
  ["France", "fr", "33"],
  ["French Guiana (Guyane française)", "gf", "594"],
  ["French Polynesia (Polynésie française)", "pf", "689"],
  ["French Southern and Antarctic Lands", "tf", "262"],
  ["Gabon", "ga", "241"],
  ["Gambia", "gm", "220"],
  ["Georgia (საქართველო)", "ge", "995"],
  ["Germany (Deutschland)", "de", "49"],
  ["Ghana (Gaana)", "gh", "233"],
  ["Gibraltar", "gi", "350"],
  ["Greece (Ελλάδα)", "gr", "30"],
  ["Greenland (Kalaallit Nunaat)", "gl", "299"],
  ["Grenada", "gd", "1473"],
  ["Guadeloupe", "gp", "590"],
  ["Guam", "gu", "1671"],
  ["Guatemala", "gt", "502"],
  ["Guernsey", "gg", "44"],
  ["Guinea (Guinée)", "gn", "224"],
  ["Guinea-Bissau (Guiné Bissau)", "gw", "245"],
  ["Guyana", "gy", "592"],
  ["Haiti", "ht", "509"],
  ["Heard Island and McDonald Islands", "hm", "672"],
  ["Honduras", "hn", "504"],
  ["Hong Kong (香港)", "hk", "852"],
  ["Hungary (Magyarország)", "hu", "36"],
  ["Iceland (Ísland)", "is", "354"],
  ["Indonesia", "id", "62"],
  ["Iran (‫ایران‬‎)", "ir", "98"],
  ["Iraq (‫العراق‬‎)", "iq", "964"],
  ["Ireland", "ie", "353"],
  ["Isle of Man", "im", "44"],
  ["Israel (‫ישראל‬‎)", "il", "972"],
  ["Italy (Italia)", "it", "39"],
  ["Jamaica", "jm", "1876"],
  ["Japan (日本)", "jp", "81"],
  ["Jersey", "je", "44"],
  ["Jordan (‫الأردن‬‎)", "jo", "962"],
  ["Kazakhstan (Казахстан)", "kz", "7"],
  ["Kenya", "ke", "254"],
  ["Kiribati", "ki", "686"],
  ["Kosovo", "xk", "383"],
  ["Kuwait (‫الكويت‬‎)", "kw", "965"],
  ["Kyrgyzstan (Кыргызстан)", "kg", "996"],
  ["Laos (ລາວ)", "la", "856"],
  ["Latvia (Latvija)", "lv", "371"],
  ["Lebanon (‫لبنان‬‎)", "lb", "961"],
  ["Lesotho", "ls", "266"],
  ["Liberia", "lr", "231"],
  ["Libya (‫ليبيا‬‎)", "ly", "218"],
  ["Liechtenstein", "li", "423"],
  ["Lithuania (Lietuva)", "lt", "370"],
  ["Luxembourg", "lu", "352"],
  ["Macau (澳門)", "mo", "853"],
  ["Macedonia (FYROM) (Македонија)", "mk", "389"],
  ["Madagascar (Madagasikara)", "mg", "261"],
  ["Malawi", "mw", "265"],
  ["Malaysia", "my", "60"],
  ["Maldives", "mv", "960"],
  ["Mali", "ml", "223"],
  ["Malta", "mt", "356"],
  ["Marshall Islands", "mh", "692"],
  ["Martinique", "mq", "596"],
  ["Mauritania (‫موريتانيا‬‎)", "mr", "222"],
  ["Mauritius (Moris)", "mu", "230"],
  ["Mayotte", "yt", "262"],
  ["Mexico (México)", "mx", "52"],
  ["Micronesia", "fm", "691"],
  ["Moldova (Republica Moldova)", "md", "373"],
  ["Monaco", "mc", "377"],
  ["Mongolia (Монгол)", "mn", "976"],
  ["Montenegro (Crna Gora)", "me", "382"],
  ["Montserrat", "ms", "1664"],
  ["Morocco (‫المغرب‬‎)", "ma", "212"],
  ["Mozambique (Moçambique)", "mz", "258"],
  ["Myanmar (Burma) (မြန်မာ)", "mm", "95"],
  ["Namibia (Namibië)", "na", "264"],
  ["Nauru", "nr", "674"],
  ["Nepal (नेपाल)", "np", "977"],
  ["Netherlands (Nederland)", "nl", "31"],
  ["New Caledonia (Nouvelle-Calédonie)", "nc", "687"],
  ["New Zealand", "nz", "64"],
  ["Nicaragua", "ni", "505"],
  ["Niger (Nijar)", "ne", "227"],
  ["Nigeria", "ng", "234"],
  ["Niue", "nu", "683"],
  ["Norfolk Island", "nf", "672"],
  ["North Korea (조선 민주주의 인민 공화국)", "kp", "850"],
  ["Northern Mariana Islands", "mp", "1670"],
  ["Norway (Norge)", "no", "47"],
  ["Oman (‫عُمان‬‎)", "om", "968"],
  ["Pakistan (‫پاکستان‬‎)", "pk", "92"],
  ["Palau", "pw", "680"],
  ["Palestine (‫فلسطين‬‎)", "ps", "970"],
  ["Panama (Panamá)", "pa", "507"],
  ["Papua New Guinea", "pg", "675"],
  ["Paraguay", "py", "595"],
  ["Peru (Perú)", "pe", "51"],
  ["Philippines", "ph", "63"],
  ["Pitcairn Islands", "pn", "64"],
  ["Poland (Polska)", "pl", "48"],
  ["Portugal", "pt", "351"],
  ["Qatar (‫قطر‬‎)", "qa", "974"],
  ["Réunion (La Réunion)", "re", "262"],
  ["Romania (România)", "ro", "40"],
  ["Russia (Россия)", "ru", "7"],
  ["Rwanda", "rw", "250"],
  ["Saint Barthélemy (Saint-Barthélemy)", "bl", "590"],
  ["Saint Helena", "sh", "290"],
  ["Saint Kitts and Nevis", "kn", "1869"],
  ["Saint Lucia", "lc", "1758"],
  ["Saint Martin (Saint-Martin (partie française))", "mf", "590"],
  ["Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)", "pm", "508"],
  ["Saint Vincent and the Grenadines", "vc", "1784"],
  ["Samoa", "ws", "685"],
  ["San Marino", "sm", "378"],
  ["São Tomé and Príncipe (São Tomé e Príncipe)", "st", "239"],
  ["Saudi Arabia (‫المملكة العربية السعودية‬‎)", "sa", "966"],
  ["Senegal (Sénégal)", "sn", "221"],
  ["Serbia (Србија)", "rs", "381"],
  ["Seychelles", "sc", "248"],
  ["Sierra Leone", "sl", "232"],
  ["Singapore", "sg", "65"],
  ["Sint Maarten", "sx", "1721"],
  ["Slovakia (Slovensko)", "sk", "421"],
  ["Slovenia (Slovenija)", "si", "386"],
  ["Solomon Islands", "sb", "677"],
  ["Somalia (Soomaaliya)", "so", "252"],
  ["South Africa", "za", "27"],
  ["South Georgia and the South Sandwich Islands", "gs", "500"],
  ["South Korea (대한민국)", "kr", "82"],
  ["South Sudan (‫جنوب السودان‬‎)", "ss", "211"],
  ["Spain (España)", "es", "34"],
  ["Sri Lanka (ශ්‍රී ලංකාව)", "lk", "94"],
  ["Sudan (‫السودان‬‎)", "sd", "249"],
  ["Suriname", "sr", "597"],
  ["Svalbard and Jan Mayen", "sj", "47"],
  ["Swaziland", "sz", "268"],
  ["Sweden (Sverige)", "se", "46"],
  ["Switzerland (Schweiz)", "ch", "41"],
  ["Syria (‫سوريا‬‎)", "sy", "963"],
  ["Taiwan (台灣)", "tw", "886"],
  ["Tajikistan", "tj", "992"],
  ["Tanzania", "tz", "255"],
  ["Thailand (ไทย)", "th", "66"],
  ["Timor-Leste", "tl", "670"],
  ["Togo", "tg", "228"],
  ["Tokelau", "tk", "690"],
  ["Tonga", "to", "676"],
  ["Trinidad and Tobago", "tt", "1868"],
  ["Tunisia (‫تونس‬‎)", "tn", "216"],
  ["Turkey (Türkiye)", "tr", "90"],
  ["Turkmenistan", "tm", "993"],
  ["Turks and Caicos Islands", "tc", "1649"],
  ["Tuvalu", "tv", "688"],
  ["U.S. Virgin Islands", "vi", "1340"],
  ["Uganda", "ug", "256"],
  ["Ukraine (Україна)", "ua", "380"],
  ["United Arab Emirates (‫الإمارات العربية المتحدة‬‎)", "ae", "971"],
  ["United Kingdom", "gb", "44"],
  ["Uruguay", "uy", "598"],
  ["Uzbekistan (Oʻzbekiston)", "uz", "998"],
  ["Vanuatu", "vu", "678"],
  ["Vatican City (Città del Vaticano)", "va", "39"],
  ["Venezuela", "ve", "58"],
  ["Vietnam (Việt Nam)", "vn", "84"],
  ["Wallis and Futuna", "wf", "681"],
  ["Western Sahara", "eh", "212"],
  ["Yemen (‫اليمن‬‎)", "ye", "967"],
  ["Zambia", "zm", "260"],
  ["Zimbabwe", "zw", "263"],
]

const disabledCodes = ["850", "98", "963", "53", "58"]

export const codesSelect = allCountryCodes
  .filter((item) => !disabledCodes.includes(item[2]))
  .map((item) => ({ id: `+${item[2]}`, name: `${item[0]}` }))

export const timeZones = [
  { name: "PST", id: "PST" },
  { name: "UTC", id: "UTC" },
]

export type TimeZonesType = "PST" | "UTC"

export const language = [
  { name: "English", id: "en" },
  { name: "French", id: "fr" },
]

export const reportName = [
  { name: "Daily Charge Report", id: "DailyChargeReport" },
  { name: "Host Balance Report", id: "HostBalanceReport" },
  { name: "Daily Fees Report", id: "DailyFeesReport" },
  { name: "Outgoing Transfer Report", id: "OutgoingTransferReport" },
  { name: "Security Deposit Report", id: "SecurityDepositReport" },
  { name: "Daily Transfer Report", id: "DailyTransferReport" },
  { name: "Promo Code Report", id: "PromoCodeReport" },
]

export type ReportNameType =
  | "DailyChargeReport"
  | "HostBalanceReport"
  | "DailyFeesReport"
  | "OutgoingTransferReport"
  | "SecurityDepositReport"
  | "DailyTransferReport"
  | "PromoCodeReport"

export type FormatType = "XLSX" | "PDF" | "HTML"

export const reasonArray = [
  { name: "Other", id: "Other" },
  { name: "Duplicate", id: "Duplicate" },
  { name: "Fraudulent", id: "Fraudulent" },
  {
    name: "Requested by Traveler",
    id: "Requested by Traveler",
  },
  { name: "Requested By Host", id: "Requested By Host" },
  { name: "Booking Failure", id: "Booking Failure" },
  { name: "Issue With Stay", id: "Issue With Stay" },
]

export const reasonArrays = [
  {
    name: "Other",
    id: "Other",
  },
  {
    name: "Dissatisfaction With Stay - Partial Refund",
    id: "Dissatisfaction With Stay - Partial Refund",
  },
  {
    name: "Dissatisfaction With Stay - Full Refund",
    id: "Dissatisfaction With Stay - Full Refund",
  },
  {
    name: "Property Churn",
    id: "Property Churn",
  },
  {
    name: "PMS Error",
    id: "PMS Error",
  },
  {
    name: "PMC Rule Violation",
    id: "PMC Rule Violation",
  },
  {
    name: "Property Not Accessible ",
    id: "Property Not Accessible",
  },
  {
    name: "PMC Error ",
    id: "PMC Error",
  },
  {
    name: "Fraud",
    id: "Fraud",
  },
  {
    name: "Traveler Escalation",
    id: "Traveler Escalation",
  },
  {
    name: "Traveler Request - Full Refund",
    id: "Traveler Request - Full Refund",
  },
  {
    name: "Traveler Request - Partial Refund",
    id: "Traveler Request - Partial Refund",
  },
]

export const modifyBookingArray = [
  { name: "Other", id: "Other" },
  { name: "Date Change", id: "Date Change" },
  { name: "Fee Addition - Amenity", id: "Fee Addition - Amenity" },
  { name: "Fee Addition - Pet", id: "Fee Addition - Pet" },
  { name: "Fee Addition - Traveler", id: "Fee Addition - Traveler" },
  { name: "Fee Addition - Other", id: "Fee Addition - Other" },
  { name: "Original Payment Error", id: "Original Payment Error" },
  { name: "Damages", id: "Damages" },
  { name: "Payment Adjustment", id: "Payment Adjustment" },
]

export const refundReasonList = [
  {
    name: "Issue with Stay - Entry Issue",
    id: "Issue with Stay - Entry Issue",
  },
  {
    name: "Issue with Stay - Amenities/Utilities/Appliance/Noise Issue",
    id: "Issue with Stay - Amenities/Utilities/Appliance/Noise Issue",
  },
  {
    name: "Issue with Stay - Cleanliness Issue",
    id: "Issue with Stay - Cleanliness Issue",
  },
  {
    name: "Lost Chargeback - PMC Fault - Amenities/Utilities/Appliance/Noise Issue",
    id: "Lost Chargeback - PMC Fault - Amenities/Utilities/Appliance/Noise Issue",
  },
  {
    name: "Lost Chargeback - PMC Fault - Cleanliness Issue",
    id: "Lost Chargeback - PMC Fault - Cleanliness Issue",
  },
  {
    name: "Lost Chargeback - PMC Fault - Entry Issue",
    id: "Lost Chargeback - PMC Fault - Entry Issue",
  },
  {
    name: "PMC Error - Whimstay Policy Violation",
    id: "PMC Error - Whimstay Policy Violation",
  },
  {
    name: "PMC Request - Policy Violation - PMC Fault - Age",
    id: "PMC Request - Policy Violation - PMC Fault - Age",
  },
  {
    name: "PMC Request - Policy Violation - PMC Fault - Pets",
    id: "PMC Request - Policy Violation - PMC Fault - Pets",
  },
  {
    name: "PMC Request - Relocation",
    id: "PMC Request - Relocation",
  },
  {
    name: "PMC Request - Host Churn",
    id: "PMC Request - Host Churn",
  },
  {
    name: "PMC Request - Property Churn",
    id: "PMC Request - Property Churn",
  },
  {
    name: "PMC Request- Property Inaccessible",
    id: "PMC Request- Property Inaccessible",
  },
  {
    name: "PMS Error - Booking Failure",
    id: "PMS Error - Booking Failure",
  },
  {
    name: "PMS Error - Double Booking",
    id: "PMS Error - Double Booking",
  },
  {
    name: "PMS Error - Listing Data / Fees Incorrect",
    id: "PMS Error - Listing Data / Fees Incorrect",
  },
  {
    name: "Traveler Request - Outside Cancelation Policy - Negative Property Info",
    id: "Traveler Request - Outside Cancelation Policy - Negative Property Info",
  },
  {
    name: "PMC Request - Policy Violation - Traveler Fault - Age",
    id: "PMC Request - Policy Violation - Traveler Fault - Age",
  },
  {
    name: "PMC Request - Policy Violation - Traveler Fault - Pets",
    id: "PMC Request - Policy Violation - Traveler Fault - Pets",
  },
  {
    name: "Traveler Request - Outside Cancelation Policy - Accidental Booking/Wrong Dates/Wrong Property",
    id: "Traveler Request - Outside Cancelation Policy - Accidental Booking/Wrong Dates/Wrong Property",
  },
  {
    name: "Traveler Request - Outside Cancelation Policy - Policy Violation - Age",
    id: "Traveler Request - Outside Cancelation Policy - Policy Violation - Age",
  },
  {
    name: "Traveler Request - Outside Cancelation Policy - Policy Violation - Pets",
    id: "Traveler Request - Outside Cancelation Policy - Policy Violation - Pets",
  },
  {
    name: "Traveler Request - Travel Insurance Cancelation - Changed Mind",
    id: "Traveler Request - Travel Insurance Cancelation - Changed Mind",
  },
  {
    name: "Traveler Request - Within Cancelation Policy - Accidental Booking/Wrong Dates/Wrong Property",
    id: "Traveler Request - Within Cancelation Policy - Accidental Booking/Wrong Dates/Wrong Property",
  },
  {
    name: "Traveler Request - Within Cancelation Policy - Changed Mind",
    id: "Traveler Request - Within Cancelation Policy - Changed Mind",
  },
  {
    name: "Traveler Request - Within Cancelation Policy - Policy Violation - Age",
    id: "Traveler Request - Within Cancelation Policy - Policy Violation - Age",
  },
  {
    name: "Traveler Request - Within Cancelation Policy - Policy Violation - Pets",
    id: "Traveler Request - Within Cancelation Policy - Policy Violation - Pets",
  },
  {
    name: "Traveler Request - Promo Code Redemption Issue - WELCOME25 Refund",
    id: "Traveler Request - Promo Code Redemption Issue - WELCOME25 Refund",
  },
  {
    name: "Suspected Fraud",
    id: "Suspected Fraud",
  },
  {
    name: "PMC Violation - Breach of Rental Agreement Terms (ID required/Agreement signed/CC hold for incidentals, no locals, etc.)",
    id: "PMC Violation - Breach of Rental Agreement Terms (ID required/Agreement signed/CC hold for incidentals, no locals, etc.)",
  },
  {
    name: "Whimstay control (Listing inaccuracies/missing information)",
    id: "Whimstay control (Listing inaccuracies/missing information)",
  },
  {
    name: "24h Hold - Capture Failed",
    id: "24h Hold - Capture Failed",
  },
]

export const capitalizeWords = (str: string) => {
  return (
    str &&
    str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")
  )
}

export function convertLowerCase(value: string | null) {
  return typeof value === "string" ? value.toLowerCase() : value
}

export function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
  const forbiddenKeys = ["e", "E", "+", "-", "Minus"]

  if (forbiddenKeys.includes(e.key)) {
    e.preventDefault()
  }
}

export function handlePaste(e: React.ClipboardEvent<HTMLInputElement>) {
  const pastedText = e.clipboardData.getData("text")

  if (pastedText.includes("-")) {
    e.preventDefault()
  }
}

export const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key.match(/\D/)) {
    e.preventDefault()
  }
}

export const handleOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  const inputElement = e.target
  // Remove non-numeric and non-decimal characters
  inputElement.value = inputElement.value.replace(/[^\d.]/g, "")
  // Ensure that there's only one decimal point
  const decimalCount = (inputElement.value.match(/\./g) || []).length
  if (decimalCount > 1) {
    inputElement.value = inputElement.value.slice(0, -1)
  }
  // Limit the input to two decimal digits
  const decimalDigits = inputElement.value.split(".")[1]
  if (decimalDigits && decimalDigits.length > 2) {
    inputElement.value = inputElement.value.slice(0, -1)
  }
}

export const preventExceedingRange = (
  e: React.KeyboardEvent<HTMLInputElement>,
  inputValue: string,
) => {
  // Prevent input if the value exceeds -10000 or 10000
  const currentValue = parseFloat(inputValue + e.key)
  if (currentValue < -10000 || currentValue > 10000) {
    e.preventDefault()
  }
}

export const inputAllowedMinus = (e: React.ChangeEvent<HTMLInputElement>) => {
  const inputElement = e.target
  // Allow digits, decimal point, and minus sign at the beginning
  inputElement.value = inputElement.value.replace(/[^\d.-]/g, "")

  // Ensure that there's only one minus sign at the beginning
  const minusCount = (inputElement.value.match(/-/g) || []).length
  if (
    minusCount > 1 ||
    inputElement.value.indexOf("-") > 0 ||
    inputElement.value.lastIndexOf("-") > 0
  ) {
    inputElement.value = inputElement.value.replace(/-/g, (match, offset) =>
      offset === 0 ? match : "",
    )
  }

  // Ensure that there's only one decimal point
  const decimalCount = (inputElement.value.match(/\./g) || []).length
  if (decimalCount > 1) {
    inputElement.value = inputElement.value.slice(0, -1)
  }

  // Limit the input to two decimal digits
  const decimalDigits = inputElement.value.split(".")[1]
  if (decimalDigits && decimalDigits.length > 2) {
    inputElement.value = inputElement.value.slice(0, -1)
  }
}

export function limitValueToMax(
  e: React.ChangeEvent<HTMLInputElement>,
  maxValue?: number,
) {
  const val = e.target.value
  const max = maxValue ? maxValue : 101
  const maxLength = max.toString().length - 1
  return Number(val) < max
    ? val
    : parseInt(val.substring(0, maxLength)).toString()
}

export function handleKeyPressZero(
  value: number | string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
) {
  if (value === "0") return setValue("")
}

export function calculateTravelerPayments(
  rentAmount: number,
  hostFees: number,
  taxes: number,
  discountPercentage: number,
  getCourtData: GetCourtType,
): number {
  const getDiscountPercentage = (1 - discountPercentage / 100) * rentAmount
  const travelAmount =
    rentAmount + hostFees + taxes + getCourtData.travelerServiceFee

  return getDiscountPercentage > 0
    ? getDiscountPercentage + hostFees + taxes + getCourtData.travelerServiceFee
    : travelAmount
}

export function calculateHostFeeAmount(
  rentAmount: number,
  hostFees: number,
  taxes: number,
  lessHostFees: number,
  discountPercentage: number,
  getCourtData: GetCourtType,
): number {
  const getDiscountPercentage = (1 - discountPercentage / 100) * rentAmount
  const hostAmount =
    getDiscountPercentage + hostFees + taxes - getCourtData.hostServiceFee
  const finalHost = (lessHostFees / 100) * hostAmount

  return finalHost > 0 ? hostAmount - finalHost : hostAmount
}

// Function to handle Input value decimal allowed 0 to 100 if PERCENT otherwise allow all numeric values, with up to two decimal places
export function sanitizeInputValue(value: string, isPercent = true) {
  // Remove non-numeric and non-decimal characters
  let sanitizedValue = value.replace(/[^\d.]/g, "")
  const decimalCount = (sanitizedValue.match(/\./g) || []).length // Count decimal points

  if (isPercent) {
    // allow values between 0 and 100 with up to two decimal place
    if (sanitizedValue !== "") {
      // Avoid parsing and rounding prematurely
      const numberValue = sanitizedValue

      // Handle edge cases like multiple dots or leading zeroes before decimal
      if (numberValue.includes(".")) {
        const [integerPart, decimalPart] = numberValue.split(".")

        // Ensure the integer part is between 0 and 100, and allow up to 2 decimal places
        const integerValue = Math.min(
          parseInt(integerPart || "0", 10),
          100,
        ).toString()
        const truncatedDecimalPart = decimalPart.slice(0, 2)

        // Combine integer and decimal parts, handle case where integer part is 100 and no decimals are allowed
        sanitizedValue =
          integerValue +
          (integerValue === "100" ? "" : `.${truncatedDecimalPart}`)
      } else {
        // If no decimal part, enforce the integer between 0 and 100
        sanitizedValue = Math.min(parseInt(numberValue, 10), 100).toString()
      }
    }
  } else {
    // If the sanitized value starts with "0" but has additional digits, remove the leading zero
    if (
      sanitizedValue.length > 1 &&
      sanitizedValue.startsWith("0") &&
      !sanitizedValue.startsWith("0.")
    ) {
      sanitizedValue = sanitizedValue.slice(1)
    }

    // Allow up to two decimal places for non-percent cases
    if (sanitizedValue.includes(".")) {
      const [integerPart, decimalPart] = sanitizedValue.split(".")
      sanitizedValue = `${integerPart}.${decimalPart.slice(0, 2)}`
    }
  }

  // Ensure the number of decimal places doesn't exceed two
  if (decimalCount > 1) {
    sanitizedValue = sanitizedValue.slice(0, -1)
  }

  return sanitizedValue || "0"
}

// gets the title corresponding to the given array value
export const getTitleFromArray = (
  stateValue: string,
  statusArray: { title: string; value: string }[],
) => {
  const statusTitle = statusArray.find((status) => status.value === stateValue)
  return statusTitle ? statusTitle.title : "ALL"
}
