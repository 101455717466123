import { useEffect } from "react"

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react"
import { Link, Location, useLocation } from "react-router-dom"

import CallerVerificationIcon from "components/Icons/sidebarIcons/CallerVerificationIcon"
import HostPayoutDashboardIcon from "components/Icons/sidebarIcons/HostPayoutDashboardIcon"
import ManageRoleIcon from "components/Icons/sidebarIcons/ManageRoleIcon"
import ManageTokenIcon from "components/Icons/sidebarIcons/ManageTokenIcon"
import PMSFeeManageIcon from "components/Icons/sidebarIcons/PMSFeeManageIcon"
import { getTitleForUrl } from "components/Title"
import {
  ActionType,
  ModuleType,
  UserRoleType,
  VIEW_ARRAY,
} from "configs/permissions"
import { useAppDispatch, useAppSelector } from "hooks/store/hooks"
import { selectUserRole } from "stores/auth/authSlice"
import {
  selectIsSidebarOpen,
  selectRoleDetails,
  toggleSidebar,
} from "stores/ui/uiSlice"
import { Endpoints } from "types"

import Logo from "./Icons/Logo"
import BookingsIcon from "./Icons/sidebarIcons/BookingsIcon"
import CompanyDashboardIcon from "./Icons/sidebarIcons/CompanyDashboardIcon"
import EventsIcon from "./Icons/sidebarIcons/EventsIcon"
import FAQIcon from "./Icons/sidebarIcons/FAQIcon"
import HeroBannerIcon from "./Icons/sidebarIcons/HeroBannerIcon"
import HomeConfigIcon from "./Icons/sidebarIcons/HomeConfigIcon"
import ManageUsersIcon from "./Icons/sidebarIcons/ManageUsersIcon"
import OnboardingIcon from "./Icons/sidebarIcons/OnboardingIcon"
import PromoCodesIcon from "./Icons/sidebarIcons/PromoCodesIcon"
import PropertyDashboardIcon from "./Icons/sidebarIcons/PropertyDashboardIcon"
import PropertyManageIcon from "./Icons/sidebarIcons/PropertyManageIcon"
import ReportsIcon from "./Icons/sidebarIcons/ReportsIcon"
import ReviewManagementIcon from "./Icons/sidebarIcons/ReviewManagementIcon"
import SendEmailsIcon from "./Icons/sidebarIcons/SendEmailsIcon"
import UserDashboardIcon from "./Icons/sidebarIcons/UserDashboardIcon"
import PermittedContentOnly from "./PermittedContentOnly"

type TNavItem = {
  children?: TNavItem[]
  icon: JSX.Element
  isNested: boolean
  link: string
  module: ModuleType
  title: string
}

const navItems: TNavItem[] = [
  {
    title: "Manage Users",
    icon: <ManageUsersIcon />,
    link: Endpoints.manageUsers,
    module: "ManageUsers",
    isNested: false,
  },
  {
    title: "Caller Verification",
    icon: <CallerVerificationIcon />,
    link: Endpoints.callerVerification,
    module: "CallerVerification",
    isNested: false,
  },
  {
    title: "Property Dashboard",
    icon: <PropertyDashboardIcon />,
    link: Endpoints.propertyDashboard,
    module: "PropertyDashboard",
    isNested: false,
  },
  {
    title: "User Dashboard",
    icon: <UserDashboardIcon />,
    link: Endpoints.userDashboard,
    module: "UserDashboard",
    isNested: false,
  },
  {
    title: "Booking Dashboard",
    icon: <BookingsIcon />,
    link: Endpoints.newBookingDashboard,
    module: "BookingDashboard",
    isNested: false,
  },
  {
    title: "Bcom Booking Dashboard",
    icon: <BookingsIcon />,
    link: Endpoints.bcomBookingDashboard,
    module: "BookingDashboard",
    isNested: false,
  },
  {
    title: "Company Dashboard",
    icon: <CompanyDashboardIcon />,
    link: Endpoints.companyDashboard,
    module: "CompanyDashboard",
    isNested: false,
  },
  // {
  //   title: "Whimstay Discount",
  //   icon: <CompanyDashboardIcon />,
  //   link: "",
  //   module: "WhimstayDiscount",
  //   isNested: false,
  //   children: [
  //     {
  //       title: "Company Discount",
  //       icon: <HeroBannerIcon />,
  //       link: Endpoints.manageCompanyDiscount,
  //       module: "WhimstayDiscount",
  //       isNested: true,
  //     },
  //     {
  //       title: "Property Discount",
  //       icon: <PropertyManageIcon />,
  //       link: Endpoints.managePropertyDiscount,
  //       module: "WhimstayDiscount",
  //       isNested: true,
  //     },
  //   ],
  // },
  {
    title: "Host Payout Dashboard",
    icon: <HostPayoutDashboardIcon />,
    link: Endpoints.hostPayoutDashboard,
    module: "HostPayoutDashboard",
    isNested: false,
  },
  {
    title: "FAQ",
    icon: <FAQIcon />,
    link: Endpoints.faq,
    module: "FAQ",
    isNested: false,
  },
  {
    title: "Reports",
    icon: <ReportsIcon />,
    link: Endpoints.reports,
    module: "Reports",
    isNested: false,
  },
  {
    title: "Promo Codes",
    icon: <PromoCodesIcon />,
    link: "",
    module: "PromoCodeDashboard",
    isNested: false,
    children: [
      {
        title: "Manage Promo Codes",
        icon: <PromoCodesIcon />,
        link: Endpoints.managePromoCodes,
        module: "PromoCodeDashboard",
        isNested: true,
      },
      {
        title: "Redeemed Promo Codes",
        icon: <PromoCodesIcon />,
        link: Endpoints.redeemPromoCodes,
        module: "PromoCodeDashboard",
        isNested: true,
      },
    ],
  },
  {
    title: "Manage Tokens",
    icon: <ManageTokenIcon />,
    link: Endpoints.manageTokens,
    module: "ManageTokens",
    isNested: false,
  },
  {
    title: "PMS Fees Manage",
    icon: <PMSFeeManageIcon />,
    link: Endpoints.pmsFeesManage,
    module: "PMSFeesManage",
    isNested: false,
  },
  {
    title: "Onboarding",
    icon: <OnboardingIcon />,
    link: Endpoints.onboarding,
    module: "OnboardingDashboard",
    isNested: false,
  },
  {
    title: "Events",
    icon: <EventsIcon />,
    link: Endpoints.events,
    module: "Events",
    isNested: false,
  },
  {
    title: "Send Emails",
    icon: <SendEmailsIcon />,
    link: Endpoints.sendEmails,
    module: "SendEmails",
    isNested: false,
  },
  {
    title: "Review Management",
    icon: <ReviewManagementIcon />,
    link: Endpoints.reviewManagement,
    module: "ReviewManagement",
    isNested: false,
  },
  {
    title: "Home Config.",
    icon: <HomeConfigIcon />,
    link: "",
    module: "HeroConfiguration",
    isNested: false,
    children: [
      {
        title: "Hero Banner",
        icon: <HeroBannerIcon />,
        link: Endpoints.heroBanner,
        module: "HeroConfiguration",
        isNested: true,
      },
      {
        title: "Featured Property",
        icon: <PropertyManageIcon />,
        link: Endpoints.propertyManagement,
        module: "HeroConfiguration",
        isNested: true,
      },
    ],
  },
]

const NavItem = ({
  item,
  isOpen,
  location,
  isCloseToggle,
  roleData,
}: {
  isOpen: boolean
  isCloseToggle?: boolean
  item: TNavItem
  location: Location
  roleData: {
    role: UserRoleType
    rule: { module: ModuleType; action: ActionType[] }[]
  }[]
}) => {
  const dispatch = useAppDispatch()
  const handleToggle = () => {
    if (isCloseToggle) {
      dispatch(toggleSidebar())
    }
  }

  const getUrl = location.pathname.split("/")
  const newUrl = getUrl[1] + "/" + getUrl[2]
  const isActiveDetails = (url: string, itemTitle: string): boolean => {
    const title = getTitleForUrl(url)
    return title === itemTitle
  }
  const activeDetails = isActiveDetails(newUrl, item.title)
  return item.children ? (
    <AccordionItem border={"none"}>
      <AccordionButton
        as={Button}
        variant='navItem'
        size='lg'
        isActive={item.link === location.pathname}
        leftIcon={item.icon}
        pl={!isOpen ? "30px" : "12px"}
        transition={"all 0.2s ease"}
        mb={3}
      >
        <Text
          display={isOpen ? "block" : "none"}
          variant='navColor'
          size='sm'
          fontWeight='600'
        >
          {item.title}
        </Text>
        <AccordionIcon ml='auto' display={isOpen ? "block" : "none"} />
      </AccordionButton>
      <AccordionPanel p={0} onClick={handleToggle}>
        {isOpen &&
          item.children?.map((nestedItem) => (
            <PermittedContentOnly key={nestedItem.title} module={item.module}>
              <NavItem
                isOpen={isOpen}
                item={nestedItem}
                location={location}
                key={item.title}
                roleData={roleData}
              />
            </PermittedContentOnly>
          ))}
      </AccordionPanel>
    </AccordionItem>
  ) : (
    <Button
      as={Link}
      to={item.link}
      variant='navItem'
      size='lg'
      isActive={item.link === location.pathname || activeDetails}
      leftIcon={item.icon}
      transition={"all 0.2s ease"}
      pl={item.isNested ? "24px" : !isOpen ? "30px" : "12px"}
      onClick={handleToggle}
      mb={3}
    >
      <Text
        display={isOpen ? "block" : "none"}
        variant='navColor'
        size='sm'
        fontWeight='600'
      >
        {item.title}
      </Text>
    </Button>
  )
}

const SideDrawerContent = (props: {
  isCloseToggle?: boolean
  roleData: {
    role: UserRoleType
    rule: { module: ModuleType; action: ActionType[] }[]
  }[]
}) => {
  const { isCloseToggle, roleData } = props
  const userRole = useAppSelector(selectUserRole)
  const location = useLocation()
  const isOpen = useAppSelector(selectIsSidebarOpen)

  if (userRole === "DEVELOPER") {
    const editColumns: TNavItem = {
      title: "Manage Role",
      icon: <ManageRoleIcon />,
      link: Endpoints.manageRole,
      module: "ManageRole",
      isNested: false,
    }
    navItems.unshift(editColumns)

    const editColumnItem: TNavItem = {
      title: "Regional Landing",
      icon: <HomeConfigIcon />,
      link: Endpoints.regionalLanding,
      module: "HeroConfiguration",
      isNested: false,
    }
    navItems.unshift(editColumnItem)
  }

  if (userRole === "DEVELOPER") {
    // Create a new menu item for Booking Dashboard
    const bookingDashboardItem: TNavItem = {
      title: "Old Booking Dashboard",
      icon: <BookingsIcon />,
      link: Endpoints.bookingDashboard,
      module: "BookingDashboard",
      isNested: false,
    }

    // Find the index of the "New Booking Dashboard" menu item
    const newBookingDashboardIndex = navItems.findIndex(
      (item) =>
        item.module === "BookingDashboard" &&
        item.title === "Booking Dashboard",
    )

    // If "New Booking Dashboard" exists, add "Booking Dashboard" above it
    if (newBookingDashboardIndex !== -1) {
      navItems.splice(newBookingDashboardIndex, 0, bookingDashboardItem)
    }
  }

  const set = new Set()
  const column =
    navItems &&
    navItems.filter((item) => {
      const isDuplicate = set.has(item.title)
      set.add(item.title)
      return !isDuplicate
    })

  const filteredData = roleData.filter((item) => item.role === userRole)

  const newItem: any[] = []
  VIEW_ARRAY.map((item) => {
    return filteredData.map((abcObj) => {
      abcObj.rule.map((it) => {
        if (item.module === it.module) {
          if (it.action.includes(item.action as ActionType)) {
            newItem.push(it)
            return it
          }
        }
      })
    })
  })

  const finalNavItem: TNavItem[] = []
  newItem.map((item: { module: string }) => {
    column.map((it) => {
      if (item.module === it.module) {
        finalNavItem.push(it)
        return it
      }
    })
  })
  useEffect(() => {
    if (finalNavItem && finalNavItem[0]) {
      localStorage.setItem("defaultLink", finalNavItem[0].link)
    }
  }, [finalNavItem])

  return (
    <VStack spacing={0}>
      <Center w='full' m={"15px 10px 35px"}>
        <Logo w={isOpen ? "50%" : "75%"} />
      </Center>
      <Accordion allowToggle p='15px 10px 10px 10px'>
        {userRole &&
          finalNavItem.map((item) => (
            <PermittedContentOnly key={item.title} module={item.module}>
              <NavItem
                key={item.title}
                item={item}
                isOpen={isOpen}
                location={location}
                isCloseToggle={isCloseToggle}
                roleData={roleData}
              />
            </PermittedContentOnly>
          ))}
      </Accordion>
    </VStack>
  )
}

const SideDrawer = () => {
  const isOpen = useAppSelector(selectIsSidebarOpen)
  const dispatch = useAppDispatch()
  const handleToggle = () => {
    dispatch(toggleSidebar())
  }
  const roleData = useAppSelector(selectRoleDetails)
  const [isDesktop] = useMediaQuery("(min-width: 768px)")

  return (
    <>
      {isDesktop ? (
        <Box
          position={"sticky"}
          top='0'
          left={0}
          zIndex={1}
          w={{ base: "0px", md: isOpen ? "300px" : "90px" }}
          minW={{ base: "0px", md: isOpen ? "300px" : "90px" }}
          maxW='300px'
          h='100vh'
          bg='offWhite'
          borderRight={"1px solid #EEE"}
          overflowY={"auto"}
          transition={"all 0.2s ease-in"}
          className='hideScrollbar'
        >
          <SideDrawerContent roleData={roleData} />
        </Box>
      ) : (
        <Drawer isOpen={isOpen} placement='left' onClose={handleToggle}>
          <DrawerOverlay />
          <DrawerContent p='0' maxW={"300px"}>
            <DrawerCloseButton />
            <DrawerBody p='0' className='hideScrollbar' bg='offWhite'>
              <SideDrawerContent isCloseToggle={true} roleData={roleData} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}

export default SideDrawer
