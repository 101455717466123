export type UserRoleType =
  | "DEVELOPER"
  | "ROOTADMIN"
  | "ADMIN"
  | "TAADMIN"
  | "TAUSERADMIN"
  | "AMADMIN"
  | "AMUSERADMIN"
  | "MARKETINGADMIN"

export type ModuleType =
  | "ManageRole"
  | "PropertyDashboard"
  | "UserDashboard"
  | "Reports"
  | "Events"
  | "ManageUsers"
  | "FAQ"
  | "PromoCodeDashboard"
  | "SendEmails"
  | "BookingDashboard"
  | "NewBookingDashboard"
  | "bcomBookingDashboard"
  | "CompanyDashboard"
  | "WhimstayDiscount"
  | "OnboardingDashboard"
  | "HostPayoutDashboard"
  | "ManageTokens"
  | "PMSFeesManage"
  | "CallerVerification"
  | "HeroConfiguration"
  | "PropertyManagement"
  | "RegionalLanding"
  | "ReviewManagement"

export type ActionType =
  //ManageRole
  | "viewManageRole"

  // ManageUsers
  | "addManageUsers"
  | "viewManageUsers"
  | "editManageUsers"
  | "deleteManageUsers"

  //  PropertyDashboard
  | "viewPropertyDashboard"

  // UserDashboard
  | "viewUserDashboard"

  // BookingDashboard
  | "viewBookingDashboard"
  | "cancelBooking"
  | "cancelPayout"
  | "editBooking"

  //bcomBookingDashboard
  | "viewBcomBookingDashboard"
  | "editBcomBooking"

  // FAQ
  | "addFAQ"
  | "viewFAQ"
  | "editFAQ"
  | "deleteFAQ"

  // PromoCodeDashboard
  | "addPromoCodes"
  | "viewPromoCodes"
  | "editPromoCodes"
  | "deletePromoCodes"

  // CompanyDashboard
  | "viewCompanyDashboard"
  | "editCompanyFees"
  | "showAdditionalLastMinuteDiscount"

  // WhimstayDiscount
  | "viewWhimstayDiscount"
  | "editCompanyDiscount"

  // HostPayoutDashboard
  | "viewHostPayoutDashboard"
  | "editHostPayoutDashboard"
  | "cancelPayoutHostPayoutDashboard"

  // Reports
  | "viewGenerateReports"

  // ManageTokens
  | "addManageTokens"
  | "viewManageTokens"
  | "editManageTokens"

  // PMSFeesManage
  | "addPMSFeesManage"
  | "viewPMSFeesManage"
  | "editPMSFeesManage"

  // Onboarding"
  | "viewOnboardingDashboard"
  | "changePMCIDOnboarding"
  | "resendInviteLinkOnboarding"
  | "createCompanyPMCIDOnboarding"

  // Events
  | "viewEvents"

  // SendEmail
  | "viewSendEmails"
  | "bookingCancellationEmailButton"
  | "bookingConfirmationEmailButton"

  // ReviewManagement
  | "viewReviewManagement"

  // CallerVerification
  | "SubmitCallerVerification"

  //HeroConfiguration
  | "viewHeroConfiguration"

export const ROLE_ACCESS_RULES: {
  role: UserRoleType
  rule: {
    module: ModuleType
    action: ActionType[]
  }[]
}[] = [
  {
    role: "DEVELOPER",
    rule: [
      {
        module: "ManageRole",
        action: ["viewManageRole"],
      },
      {
        module: "PropertyDashboard",
        action: ["viewPropertyDashboard"],
      },
      {
        module: "CallerVerification",
        action: ["SubmitCallerVerification"],
      },
      {
        module: "PMSFeesManage",
        action: ["addPMSFeesManage", "viewPMSFeesManage", "editPMSFeesManage"],
      },
      {
        module: "ManageTokens",
        action: ["addManageTokens", "viewManageTokens", "editManageTokens"],
      },
      {
        module: "HostPayoutDashboard",
        action: [
          "viewHostPayoutDashboard",
          "editHostPayoutDashboard",
          "cancelPayoutHostPayoutDashboard",
        ],
      },
      {
        module: "UserDashboard",
        action: ["viewUserDashboard"],
      },
      {
        module: "BookingDashboard",
        action: [
          "viewBookingDashboard",
          "editBooking",
          "cancelBooking",
          "cancelPayout",
        ],
      },
      {
        module: "CompanyDashboard",
        action: [
          "viewCompanyDashboard",
          "editCompanyFees",
          "showAdditionalLastMinuteDiscount",
        ],
      },
      {
        module: "FAQ",
        action: ["addFAQ", "viewFAQ", "editFAQ", "deleteFAQ"],
      },
      {
        module: "Reports",
        action: ["viewGenerateReports"],
      },
      {
        module: "PromoCodeDashboard",
        action: [
          "addPromoCodes",
          "viewPromoCodes",
          "editPromoCodes",
          "deletePromoCodes",
        ],
      },
      {
        module: "OnboardingDashboard",
        action: [
          "viewOnboardingDashboard",
          "changePMCIDOnboarding",
          "resendInviteLinkOnboarding",
          "createCompanyPMCIDOnboarding",
        ],
      },

      {
        module: "Events",
        action: ["viewEvents"],
      },
      {
        module: "SendEmails",
        action: [
          "bookingConfirmationEmailButton",
          "bookingCancellationEmailButton",
        ],
      },
      {
        module: "ManageUsers",
        action: [
          "addManageUsers",
          "viewManageUsers",
          "editManageUsers",
          "deleteManageUsers",
        ],
      },
    ],
  },
  {
    role: "ROOTADMIN",
    rule: [
      {
        module: "PropertyDashboard",
        action: ["viewPropertyDashboard"],
      },
      {
        module: "CallerVerification",
        action: ["SubmitCallerVerification"],
      },
      {
        module: "ManageTokens",
        action: ["addManageTokens", "viewManageTokens", "editManageTokens"],
      },
      {
        module: "HostPayoutDashboard",
        action: ["viewHostPayoutDashboard", "editHostPayoutDashboard"],
      },
      {
        module: "UserDashboard",
        action: ["viewUserDashboard"],
      },
      {
        module: "BookingDashboard",
        action: [
          "viewBookingDashboard",
          "editBooking",
          "cancelBooking",
          "cancelPayout",
        ],
      },
      {
        module: "CompanyDashboard",
        action: ["viewCompanyDashboard", "editCompanyFees"],
      },
      {
        module: "FAQ",
        action: ["viewFAQ", "editFAQ", "deleteFAQ"],
      },
      {
        module: "Reports",
        action: ["viewGenerateReports"],
      },
      {
        module: "PromoCodeDashboard",
        action: ["viewPromoCodes", "editPromoCodes", "deletePromoCodes"],
      },
      {
        module: "OnboardingDashboard",
        action: [
          "viewOnboardingDashboard",
          "changePMCIDOnboarding",
          "resendInviteLinkOnboarding",
          "createCompanyPMCIDOnboarding",
        ],
      },

      {
        module: "Events",
        action: ["viewEvents"],
      },
      {
        module: "SendEmails",
        action: [
          "bookingConfirmationEmailButton",
          "bookingCancellationEmailButton",
        ],
      },
      // {
      //   module: "ManageUsers",
      //   action: [
      //     "addManageUsers",
      //     "viewManageUsers",
      //     "editManageUsers",
      //     "deleteManageUsers",
      //   ],
      // },
    ],
  },
  {
    role: "ADMIN",
    rule: [
      {
        module: "PropertyDashboard",
        action: ["viewPropertyDashboard"],
      },
      {
        module: "CallerVerification",
        action: ["SubmitCallerVerification"],
      },

      {
        module: "ManageTokens",
        action: ["addManageTokens", "viewManageTokens", "editManageTokens"],
      },
      {
        module: "HostPayoutDashboard",
        action: ["viewHostPayoutDashboard", "editHostPayoutDashboard"],
      },
      {
        module: "UserDashboard",
        action: ["viewUserDashboard"],
      },
      {
        module: "BookingDashboard",
        action: [
          "viewBookingDashboard",
          "editBooking",
          "cancelBooking",
          "cancelPayout",
        ],
      },
      {
        module: "CompanyDashboard",
        action: ["viewCompanyDashboard"],
      },
      {
        module: "FAQ",
        action: ["viewFAQ", "editFAQ", "deleteFAQ"],
      },
      {
        module: "Reports",
        action: ["viewGenerateReports"],
      },
      {
        module: "PromoCodeDashboard",
        action: ["viewPromoCodes", "editPromoCodes", "deletePromoCodes"],
      },
      {
        module: "OnboardingDashboard",
        action: [
          "viewOnboardingDashboard",
          "changePMCIDOnboarding",
          "resendInviteLinkOnboarding",
          "createCompanyPMCIDOnboarding",
        ],
      },

      {
        module: "Events",
        action: ["viewEvents"],
      },
      {
        module: "SendEmails",
        action: [
          "bookingConfirmationEmailButton",
          "bookingCancellationEmailButton",
        ],
      },
      // {
      //   module: "ManageUsers",
      //   action: [
      //     "addManageUsers",
      //     "viewManageUsers",
      //     "editManageUsers",
      //     "deleteManageUsers",
      //   ],
      // },
    ],
  },
  {
    role: "TAADMIN",
    rule: [
      {
        module: "PropertyDashboard",
        action: ["viewPropertyDashboard"],
      },
      {
        module: "CallerVerification",
        action: ["SubmitCallerVerification"],
      },

      {
        module: "ManageTokens",
        action: ["addManageTokens", "viewManageTokens", "editManageTokens"],
      },
      {
        module: "HostPayoutDashboard",
        action: ["viewHostPayoutDashboard", "editHostPayoutDashboard"],
      },

      {
        module: "UserDashboard",
        action: ["viewUserDashboard"],
      },
      {
        module: "BookingDashboard",
        action: [
          "viewBookingDashboard",
          "editBooking",
          "cancelBooking",
          "cancelPayout",
        ],
      },
      {
        module: "CompanyDashboard",
        action: ["viewCompanyDashboard"],
      },
      {
        module: "FAQ",
        action: ["viewFAQ", "editFAQ", "deleteFAQ"],
      },
      {
        module: "Reports",
        action: ["viewGenerateReports"],
      },
      {
        module: "PromoCodeDashboard",
        action: ["viewPromoCodes", "editPromoCodes", "deletePromoCodes"],
      },
      {
        module: "SendEmails",
        action: [
          "bookingConfirmationEmailButton",
          "bookingCancellationEmailButton",
        ],
      },
    ],
  },
  {
    role: "TAUSERADMIN",
    rule: [
      {
        module: "PropertyDashboard",
        action: ["viewPropertyDashboard"],
      },

      {
        module: "ManageTokens",
        action: ["addManageTokens", "viewManageTokens", "editManageTokens"],
      },
      {
        module: "BookingDashboard",
        action: [
          "viewBookingDashboard",
          "editBooking",
          "cancelBooking",
          "cancelPayout",
        ],
      },
      {
        module: "FAQ",
        action: ["viewFAQ", "editFAQ", "deleteFAQ"],
      },
      {
        module: "Reports",
        action: ["viewGenerateReports"],
      },
      {
        module: "PromoCodeDashboard",
        action: ["viewPromoCodes", "editPromoCodes", "deletePromoCodes"],
      },
      {
        module: "SendEmails",
        action: ["bookingConfirmationEmailButton"],
      },
    ],
  },
  {
    role: "AMADMIN",
    rule: [
      {
        module: "PropertyDashboard",
        action: ["viewPropertyDashboard"],
      },
      {
        module: "HostPayoutDashboard",
        action: ["viewHostPayoutDashboard", "editHostPayoutDashboard"],
      },
      {
        module: "BookingDashboard",
        action: [
          "viewBookingDashboard",
          "editBooking",
          "cancelBooking",
          "cancelPayout",
        ],
      },
      {
        module: "CompanyDashboard",
        action: ["viewCompanyDashboard"],
      },
      {
        module: "Reports",
        action: ["viewGenerateReports"],
      },
      {
        module: "OnboardingDashboard",
        action: [
          "viewOnboardingDashboard",
          "changePMCIDOnboarding",
          "resendInviteLinkOnboarding",
          "createCompanyPMCIDOnboarding",
        ],
      },
    ],
  },
  {
    role: "AMUSERADMIN",
    rule: [
      {
        module: "PropertyDashboard",
        action: ["viewPropertyDashboard"],
      },
      {
        module: "HostPayoutDashboard",
        action: ["viewHostPayoutDashboard", "editHostPayoutDashboard"],
      },
      {
        module: "BookingDashboard",
        action: [
          "viewBookingDashboard",
          "editBooking",
          "cancelBooking",
          "cancelPayout",
        ],
      },
      {
        module: "CompanyDashboard",
        action: ["viewCompanyDashboard"],
      },
      {
        module: "Reports",
        action: ["viewGenerateReports"],
      },
      {
        module: "OnboardingDashboard",
        action: [
          "viewOnboardingDashboard",
          "changePMCIDOnboarding",
          "resendInviteLinkOnboarding",
          "createCompanyPMCIDOnboarding",
        ],
      },
    ],
  },
]

export const RULE_TEMPLATE: {
  module: ModuleType
  action: ActionType[]
}[] = [
  {
    module: "ManageUsers",
    action: ["viewManageUsers"],
  },
  {
    module: "CallerVerification",
    action: ["SubmitCallerVerification"],
  },
  {
    module: "PropertyDashboard",
    action: ["viewPropertyDashboard"],
  },
  {
    module: "UserDashboard",
    action: ["viewUserDashboard"],
  },
  {
    module: "BookingDashboard",
    action: ["viewBookingDashboard", "editBooking"],
  },
  {
    module: "bcomBookingDashboard",
    action: ["viewBcomBookingDashboard", "editBcomBooking"],
  },
  {
    module: "CompanyDashboard",
    action: ["viewCompanyDashboard", "editCompanyFees"],
  },
  {
    module: "WhimstayDiscount",
    action: ["viewWhimstayDiscount", "editCompanyDiscount"],
  },
  {
    module: "HostPayoutDashboard",
    action: ["viewHostPayoutDashboard", "editHostPayoutDashboard"],
  },
  {
    module: "FAQ",
    action: ["viewFAQ", "editFAQ"],
  },
  {
    module: "Reports",
    action: ["viewGenerateReports"],
  },
  {
    module: "PromoCodeDashboard",
    action: ["viewPromoCodes", "editPromoCodes"],
  },
  {
    module: "ManageTokens",
    action: ["viewManageTokens"],
  },
  {
    module: "PMSFeesManage",
    action: ["viewPMSFeesManage"],
  },
  {
    module: "OnboardingDashboard",
    action: ["viewOnboardingDashboard"],
  },
  {
    module: "Events",
    action: ["viewEvents"],
  },
  {
    module: "SendEmails",
    action: ["viewSendEmails"],
  },
  {
    module: "ReviewManagement",
    action: ["viewReviewManagement"],
  },
  {
    module: "HeroConfiguration",
    action: ["viewHeroConfiguration"],
  },
]

export const VIEW_ARRAY = [
  {
    module: "ManageRole",
    action: "viewManageRole",
  },
  {
    module: "ManageUsers",
    action: "viewManageUsers",
  },
  {
    module: "CallerVerification",
    action: "SubmitCallerVerification",
  },
  {
    module: "PropertyDashboard",
    action: "viewPropertyDashboard",
  },
  {
    module: "UserDashboard",
    action: "viewUserDashboard",
  },
  {
    module: "BookingDashboard",
    action: "viewBookingDashboard",
  },
  {
    module: "bcomBookingDashboard",
    action: "viewBcomBookingDashboard",
  },
  {
    module: "CompanyDashboard",
    action: "viewCompanyDashboard",
  },
  {
    module: "WhimstayDiscount",
    action: "viewWhimstayDiscount",
  },
  {
    module: "HostPayoutDashboard",
    action: "viewHostPayoutDashboard",
  },
  {
    module: "FAQ",
    action: "viewFAQ",
  },
  {
    module: "Reports",
    action: "viewGenerateReports",
  },
  {
    module: "PromoCodeDashboard",
    action: "viewPromoCodes",
  },
  {
    module: "ManageTokens",
    action: "viewManageTokens",
  },
  {
    module: "PMSFeesManage",
    action: "viewPMSFeesManage",
  },
  {
    module: "OnboardingDashboard",
    action: "viewOnboardingDashboard",
  },
  {
    module: "Events",
    action: "viewEvents",
  },
  {
    module: "SendEmails",
    action: "viewSendEmails",
  },
  {
    module: "ReviewManagement",
    action: "viewReviewManagement",
  },
  {
    module: "HeroConfiguration",
    action: "viewHeroConfiguration",
  },
  {
    module: "RegionalLanding",
    action: "viewHeroConfiguration",
  },
]
